import React from "react"
import "./ObstacleFitKids.scss"
import HeroBanner from "../../components/HeroBanner"
import Layout from "../../components/Layout"
import { graphql, Link } from "gatsby"
import { ListGroup, ListGroupItem } from "reactstrap"

const Camp = ({ data }) => {
  const items = data.allWordpressWpObstaclefitKids.edges.map((item) => {
    return (
      <ListGroupItem key={item.node.id}>{item.node.acf.ofkday}</ListGroupItem>
    )
  })

  return (
    <Layout>
      <div className="Team">
        <HeroBanner
          image={data.file.childImageSharp.fluid}
          title="ObstacleFit Kids (OFK)"
          caption="ObstacleFit Kids are Awesome Kids"
          actionURL="#availability"
          actionText="Spaces Available"
        />
        <div className="container content">
          <p>
            For $120 a month your child can get in the best shape of their
            lives, and can do so by simply playing.
          </p>
          <p>
            When you register for the OFK Unlimited program, you will have
            access to 5 classes per week, simply pick the days you want (those
            are your days for the month) and you have access to the open gym
            sessions.
          </p>
          <p>
            Weekend afternoon to get some much needed shopping done? Imagine
            parents, you drop off your child for the class, and they can stick
            around for an open gym afterwards to burn off even more steam! ​Give
            us a call 613-726-0909 and book your child in for their free trial
            class.
          </p>
          <h2>What is OFK?</h2>
          <p>
            ObstacleFit Kids is a strength and conditioning program designed to
            effectively teach the ObstacleFit methodology to children aged 7-13
            years old through age appropriate instruction and cueing. In a group
            setting kids participate in fun and engaging workouts that deliver
            measurable results. This prepares them not only to be well rounded
            athletes but also awesome people as well. *Kids 14 and older can
            join our adult classes.
          </p>
          <h2>What Makes OFK Different?</h2>
          <p>
            OFK workouts consist of constantly varied, functional movements that
            deliver a fitness that is varied, inclusive, and totally awesome
            (VITA) and are scalable for any participants at any level. We base
            success on effort rather than comparison. These kids are training
            for life, not a small, sport specific skill set.
          </p>
          <h2>Why choose OFK at OCRA?</h2>
          <p>
            OOur coaches have had background checks and this is renewed
            annually. Your coach has studied how to teach ObstacleFit to
            children using age appropriate methods, and have years of experience
            working with youth under their belt.
          </p>
          <h2>Pricing and Information</h2>
          <p>
            If you are new and want to try the class, we offer a free trial
            during the 1st week of classes of every month (1st - 7th).
            Registration for the following month is on the 15th. Due to current
            social distancing restrictions we have a cap of 9 kids per class and
            can only offer free trials if there is available space in the class.
            We are happy to put you on a waitlist if we are full!
          </p>
          <ListGroup>
            <ListGroupItem>
              One Day OFK - $60 for once a week, no access to open gym time
              (Prices increases to $75.00 as of Sept 1st)
            </ListGroupItem>
            <ListGroupItem>
              OFK Unlimited - $120 gets you unlimited classes + access to open
              gym time (Prices increase to $120.00 as of Sept 1)
            </ListGroupItem>
            <ListGroupItem>
              3 Day OFK - $100 - Our new 3 classes per week option
            </ListGroupItem>
            <ListGroupItem>
              You must sign up for your day/s of the week and cannot switch
              until the next month (During the pandemic we are asking that you
              limit to 3 classes per week due to limited class numbers)
            </ListGroupItem>
            <ListGroupItem>
              OFK is a monthly membership and we do not allow drop ins.
            </ListGroupItem>
            <ListGroupItem>
              Those who are currently registered with us get first priority
            </ListGroupItem>
            <ListGroupItem>No sibling discounts</ListGroupItem>
            <ListGroupItem>
              You can cancel your membership anytime, we just ask for 2 weeks
              notice
            </ListGroupItem>
          </ListGroup>
          <h2>Pandemic Procedures</h2>
          <p>
            Check out our video{" "}
            <a href="https://www.youtube.com/watch?v=-IisMDDmQf8">here</a> on
            how we plan to keep your kids safe during this time.
          </p>
          <h2 id="availability">Availability</h2>
          <p>
            If this is your childs first time at OCRA please fill out a{" "}
            <Link to="waiver">waiver</Link> for them.{" "}
            <a href="tel:6137260909">Give us a call</a> for availability and to
            Register!
          </p>
          <ListGroup>{items}</ListGroup>
        </div>
      </div>
    </Layout>
  )
}

export default Camp

export const query = graphql`
  query {
    allWordpressWpObstaclefitKids(sort: { order: ASC, fields: order }) {
      edges {
        node {
          id
          acf {
            ofkday
          }
        }
      }
    }
    file(
      url: {
        eq: "https://api.ocracademy.ca/wp/wp-content/uploads/2019/08/academy-small.png"
      }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
